import { axiosBackEnd, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const getGithubReportItem = async () => {
  const token = getCookie(TOKEN)
  if (token === null) {
    return
  }
  return await axiosBackEnd.get(`/items/report`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getActiveCodeItemList = async () => {
  return await axiosBackEnd.get(`/items/activeCode`)
}

export const getCoinItemList = async () => {
  return await axiosBackEnd.get(`/items/coin`)
}

export const getVipItemList = async () => {
  return await axiosBackEnd.get(`/items/vip`)
}

export const getItemByOrderId = async (outId, tradeType) => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/items/orders/${outId}/${tradeType}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const generatePreOrder = async (itemId, tradeType) => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/items/buyer/${itemId}/${tradeType}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
