import React from "react"
import { navigate } from "gatsby"

import { MEMBERSHIP_SIDEBAR } from "../utils/constant"
import gift from "../icons/gift.svg"

import "./MembershipSidebar.css"

export default (props) => {
  return (
    <div className="mdnice-membership-sidebar">
      <div
        className={props.sidebarType.name === MEMBERSHIP_SIDEBAR.VIP.name ? "mdnice-membership-sidebar-row-active" : "mdnice-membership-sidebar-row"}
        onClick={() => navigate(MEMBERSHIP_SIDEBAR.VIP.url)}
      >
        <p>{MEMBERSHIP_SIDEBAR.VIP.name}</p>
      </div>
      <div
        className={props.sidebarType.name === MEMBERSHIP_SIDEBAR.INVITE.name ? "mdnice-membership-sidebar-row-active" : "mdnice-membership-sidebar-row"}
        onClick={() => navigate(MEMBERSHIP_SIDEBAR.INVITE.url)}
      >
        <p>{MEMBERSHIP_SIDEBAR.INVITE.name}</p>
        <img alt="" src={gift} className="mdnice-gift-icon" />
      </div>
      <div
        className={props.sidebarType.name === MEMBERSHIP_SIDEBAR.OPEN_VIP.name ? "mdnice-membership-sidebar-row-active" : "mdnice-membership-sidebar-row"}
        onClick={() => navigate(MEMBERSHIP_SIDEBAR.OPEN_VIP.url)}
      >
        <p>{MEMBERSHIP_SIDEBAR.OPEN_VIP.name}</p>
      </div>
      <div
        className={props.sidebarType.name === MEMBERSHIP_SIDEBAR.SELL_COIN.name ? "mdnice-membership-sidebar-row-active" : "mdnice-membership-sidebar-row"}
        onClick={() => navigate(MEMBERSHIP_SIDEBAR.SELL_COIN.url)}
      >
        <p>{MEMBERSHIP_SIDEBAR.SELL_COIN.name}</p>
      </div>
    </div>
  )
}
