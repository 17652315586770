import React from "react"
import { navigate } from "gatsby"

import Layout from "../../layouts"

import { getVipItemList } from "../../api/item"
import { getUserSelf } from "../../api/user"
import "./open-vip.css"

import vip from "../../icons/vip.svg"
import superVip from "../../icons/superVip.svg"
import noVip from "../../icons/noVip.svg"
import { MEMBERSHIP_SIDEBAR, VIP_TYPE } from "../../utils/constant"
import { Tooltip } from "antd"
import MembershipSidebar from "../../components/MembershipSidebar"

class SellCoin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vipItemList: [],
      superVipItemList: [],
      visible: false,
      username: "",
      vipType: VIP_TYPE.USER,
      vipExpireTime: "",
    }
  }

  componentDidMount() {
    this.initVipItem()
    this.initUserSelf()
  }

  initVipItem = async () => {
    const res = await getVipItemList()
    if (res.data.success) {
      const { vipItemList, superVipItemList } = res.data.data
      this.setState({ vipItemList, superVipItemList })
    }
  }

  initUserSelf = async () => {
    const res = await getUserSelf()
    if (res && res.data.success) {
      const { vipType, vipExpireTime, username } = res.data.data
      this.setState({ vipType, vipExpireTime, username })
    }
  }

  render() {
    const { vipItemList, superVipItemList, vipType, vipExpireTime, username } = this.state
    let vipText = null;
    if (vipType === VIP_TYPE.USER) {
      if (vipExpireTime) {
        vipText = (
          <p className="vip-expire-reopen">
            会员 {vipExpireTime} 已过期，重新开通
          </p>
        );
      }
    } else {
      vipText = <p className="vip-expire-time">{`会员到期：${vipExpireTime}`}</p>;
    }
    return (
      <Layout isSpacerDisplay={true} title="会员开通">
        <div className="mdnice-membership-container">
          <div className="mdnice-membership-table-container">
            <MembershipSidebar sidebarType={MEMBERSHIP_SIDEBAR.OPEN_VIP} />

            <div className="mdnice-membership-content">
              <div className="vip-container">
                <div className="vip-top-container">
                  <span className="vip-description">{username}，您的会员等级为：</span>
                  {vipType === VIP_TYPE.USER &&
                    <Tooltip placement="top" title="普通用户"><img alt="" src={noVip} className="level-icon" /></Tooltip>}
                  {vipType === VIP_TYPE.VIP &&
                    <Tooltip placement="top" title="高级会员"><img alt="" src={vip} className="level-icon" /></Tooltip>}
                  {vipType === VIP_TYPE.SUPER_VIP &&
                    <Tooltip placement="top" title="超级会员"><img alt="" src={superVip} className="level-icon" /></Tooltip>}
                </div>
                {vipText}
                <p className="vip-extra">可通过购买会员进行开通或续期</p>
              </div>

              <h2 className="mdnice-super-vip-title">
                <img alt="" src={superVip} className="vip-header-icon" />
                <span>超级会员</span>
              </h2>
              <div className="mdnice-membership-item-list">
                {superVipItemList.map(item => (
                  <div
                    className="mdnice-sell-active-code-coloum"
                    key={item.name}
                    onClick={() => {
                      navigate(`/items?id=${item.itemId}&type=vip`)
                    }}
                  >
                    <div className="mdnice-sell-active-code-row">
                      <p className="mdnice-sell-active-code-name">
                        {item.name}
                      </p>
                      <p className="mdnice-sell-active-code-price">
                        <span>¥</span>
                        {item.price / 100}
                      </p>
                      <p className="mdnice-sell-active-code-per-price ">
                        {`¥${(item.price / 100 / item.vipFeature.validDay).toFixed(2)}/天`}
                      </p>
                      <p className="mdnice-sell-active-code-device ">
                        支持 {item.vipFeature.activeCodeDeviceNum} 台设备
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <h2 className="mdnice-super-vip-title">
                <img alt="" src={vip} className="vip-header-icon" />
                <span>高级会员</span>
              </h2>
              <div className="mdnice-membership-item-list">
                {vipItemList.map(item => (
                  <div
                    className="mdnice-sell-active-code-coloum"
                    key={item.name}
                    onClick={() => {
                      navigate(`/items?id=${item.itemId}&type=vip`)
                    }}
                  >
                    <div className="mdnice-sell-active-code-row">
                      <p className="mdnice-sell-active-code-name">
                        {item.name}
                      </p>
                      <p className="mdnice-sell-active-code-price">
                        <span>¥</span>
                        {item.price / 100}
                      </p>
                      <p className="mdnice-sell-active-code-per-price ">
                        {`¥${(item.price / 100 / item.vipFeature.validDay).toFixed(2)}/天`}
                      </p>
                      <p className="mdnice-sell-active-code-device ">
                        支持 {item.vipFeature.activeCodeDeviceNum} 台设备
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SellCoin
